<template>
  <b-modal v-model="isShow" centered size="sm" hide-footer>
    <h4>
      {{ $t("general.selectAction") }}
    </h4>
    <hr />
    <b-button
      class="mb-1"
      block
      :disabled="
        !$can('create', 'payment') ||
        ![vehicleState.PENDING_PAYMENT, vehicleState.PARTIAL_PAID].includes(
          Number(item.status)
        )
      "
      :variant="
        !$can('create', 'payment') ||
        ![vehicleState.PENDING_PAYMENT, vehicleState.PARTIAL_PAID].includes(
          Number(item.status)
        )
          ? 'secondary'
          : 'success'
      "
      @click="createPayment"
    >
      {{ $t("button.createPayment") }}
    </b-button>
    <b-button
      class="mb-1"
      block
      @click="discount"
      :disabled="
        !$can('create', 'payment') ||
        ![vehicleState.PENDING_PAYMENT, vehicleState.PARTIAL_PAID].includes(
          Number(item.status)
        )
      "
      :variant="
        !$can('create', 'payment') ||
        ![vehicleState.PENDING_PAYMENT, vehicleState.PARTIAL_PAID].includes(
          Number(item.status)
        )
          ? 'secondary'
          : 'warning'
      "
    >
      {{ $t("button.discount") }}
    </b-button>
    <b-button
      class="mb-1"
      block
      @click="topup"
      :disabled="
        !$can('create', 'payment') ||
        ![vehicleState.PENDING_PAYMENT, vehicleState.PARTIAL_PAID].includes(
          Number(item.status)
        )
      "
      :variant="
        !$can('create', 'payment') ||
        ![vehicleState.PENDING_PAYMENT, vehicleState.PARTIAL_PAID].includes(
          Number(item.status)
        )
          ? 'secondary'
          : 'primary'
      "
    >
      {{ $t("button.topup") }}
    </b-button>
  </b-modal>
</template>

<script>
import { BModal, BButton } from "bootstrap-vue";

export default {
  components: {
    BModal,
    BButton,
  },
  data() {
    return {
      isShow: false,
      item: {},
    };
  },
  mounted() {},
  methods: {
    show(item) {
      this.item = {
        ...item,
      };
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    createPayment() {
      this.hide();
      this.$emit("create", this.item);
    },
    discount() {
      this.hide();
      this.$emit("discount", this.item);
    },
    topup() {
      this.hide();
      this.$emit("topup", this.item);
    },
  },
};
</script>