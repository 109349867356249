export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
    hideToggle: true,
  },
  {
    key: 'createdAt',
    label: 'field.payDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'createdBy',
    label: 'field.createdBy',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'invoiceNo',
    label: 'field.invoice',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'dealer',
    label: 'field.dealer',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'payAmount',
    label: 'field.payAmount',
  },
  {
    key: 'paymentType',
    label: 'field.paymentMethod',
    thClass: 'text-left',
    tdClass: 'text-left text-capitalize',
  },
  {
    key: 'status',
    label: 'field.status',
  },
  {
    key: 'note',
    label: 'field.remark',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
  },
];
