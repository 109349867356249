export default [
  {
    key: 'increasement',
    label: '#',
    thClass: 'w-50px',
    hideToggle: true,
    skipExport: true,
  },
  {
    key: 'bidDate',
    sortable: true,
    sortField: 'bidDate',
    label: 'field.auctionDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'lot',
    label: 'field.lot',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'vinNumber',
    label: 'field.vinNumber',
    stickyColumn: true,
    variant: 'light',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'year',
    label: 'field.year',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'brandName',
    label: 'field.brand',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'model',
    label: 'field.model',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'bdPic',
    label: 'field.bdPic',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'status',
    label: 'field.status',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'location',
    label: 'field.location',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'dealer',
    label: 'field.dealer',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'soldPrice',
    label: 'field.finalPrice',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'adjustAmount',
    label: 'field.discount',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'revisedPrice',
    label: 'field.revisedPrice',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'payAmount',
    label: 'field.payAmount',
  },
  {
    key: 'remainAmount',
    label: 'field.balance',
  },
  {
    key: 'payDate',
    label: 'field.payDate',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'paymentType',
    label: 'field.paymentMethod',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'verified',
    label: 'field.verified',
    thClass: 'text-left',
    tdClass: 'text-left',
  },
  {
    key: 'actions',
    label: 'field.action',
    hideToggle: true,
    skipExport: true,
  },
];
