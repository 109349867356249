export default [
  {
    key: 'amount',
    label: 'field.topupAmount',
    rules: 'required|decimal:2',
    type: 'currency',
    prefix: '$',
    cols: 12,
  },
  {
    key: 'documentId',
    label: 'field.document',
    rules: 'required',
    type: 'async-file-uploader',
    cols: 12,
  },
  {
    key: 'remark',
    label: 'field.remark',
    rules: 'required|max:200',
    type: 'textarea',
    cols: 12,
  },
]
