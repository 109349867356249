<template>
  <b-modal
    centered
    :title="$t('field.discount')"
    size="xl"
    hide-footer
    v-model="isShow"
  >
    <div>
      <n-table
        ref="table"
        :key="`table-${key}`"
        @sort-changed="sortChanged"
        :fields="fields"
        :items="items"
        :busy="loading"
        :current-page="params.page"
        :per-page="$store.state.pagination.perPage"
        :total="total"
        :updatable="false"
        :deletable="false"
        :resource="resource"
        :route="route"
        :loading="loading"
      >
        <template #cell(createdBy)="data">
          <span v-if="data.item.createdBy">
            {{ data.item.createdBy.name }}
          </span>
          <span v-else>-</span>
        </template>
        <template #cell(type)="data">
          {{ $t(`adjustPaymentType.${data.item.type}`) }}
        </template>
        <template #cell(document)="data">
          <b-button
            v-b-tooltip.hover
            :title="$t('button.document')"
            variant="primary"
            pill
            size="sm"
            @click="viewDocument(data.item)"
            v-if="data.item.document"
          >
            <feather-icon icon="FolderIcon" />
          </b-button>
          <span v-else> - </span>
        </template>
        <template #cell(amount)="data">
          <span
            v-if="data.item.amount"
            :class="{
              'text-success': data.item.amount > 0,
              'text-danger': data.item.amount < 0,
            }"
          >
            {{ data.item.amount | currency }}
          </span>
          <span v-else>0</span>
        </template>
      </n-table>
      <n-pagination
        ref="pagination"
        :total="total"
        :per-page="$store.state.pagination.perPage"
        :page="params.page"
        @change="list"
      ></n-pagination>
    </div>
  </b-modal>
</template>

<script>
import { BModal, BBadge, BButton } from "bootstrap-vue";
import TableFields from "./tableFields";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";
import NAsyncSingleSelect from "@/components/NAsyncSingleSelect";
import NTable from "@/components/NTable";
import NPagination from "@/components/NPagination";

const PaymentAdjustmentRepository = Repository.get("paymentAdjustment");

export default {
  components: {
    BModal,
    BBadge,
    BButton,

    NAsyncSingleSelect,
    NTable,
    NPagination,
    NInput,
  },
  data() {
    return {
      key: 1,
      isShow: false,
      loading: false,
      items: [],
      item: {},
      total: 0,
      params: {},
      query: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || null,
        sort: this.$route.query.sort || null,
      },
    };
  },
  mounted() {},
  methods: {
    viewDocument(item) {
      if (item.document) {
        if (item.document.endsWith(".pdf")) {
          window.open(item.document, "_blank").focus();
        } else {
          this.$imagePreview({
            initIndex: 0,
            images: [item.document],
            zIndex: 9999,
            isEnableDownloadImage: false,
            isEnableImagePageIndicator: false,
            isEnableBlurBackground: true,
            onClose: () => {},
          });
        }
      }
    },
    show(item) {
      this.item = {
        ...item,
      };
      this.items = [];
      this.total = 0;
      this.query.page = 1;
      this.getData();
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    updateQuerySting() {
      this.$router.replace({ query: this.query });
    },
    list(page = 1) {
      this.query.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.query.order = sortBy;
      this.query.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search() {
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.query.page = 1;
      this.$refs.pagination.reset();
      this.query = {
        ...this.query,
        ...this.params,
      };
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      const params = {
        ...this.query,
        searchFields: this.searchFields,
        query: [
          {
            searchby: "payment_id",
            searchoperator: "=",
            search: this.item.id,
          },
        ],
      };
      PaymentAdjustmentRepository.index(params)
        .then((response) => {
          const data = response?.data?.data;
          if (data) {
            this.items = [...data.list];
            this.total = data.total;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const fields = [...TableFields];
    const resource = "payment";
    const route = "payment";

    return {
      fields,
      resource,
      route,
    };
  },
};
</script>