<template>
  <b-modal
    ok-variant="success"
    cancel-variant="outline-secondary"
    :cancel-title="$t('button.close')"
    centered
    no-close-on-backdrop
    :title="$t('general.keyinTopupAmount')"
    @ok="handleOk"
    size="lg"
    v-model="isShow"
  >
    <validation-observer ref="payment">
      <n-input :fields="formInput" v-model="data"> </n-input>
    </validation-observer>

    <template #modal-ok>
      <b-spinner small label="Spinning" v-if="loading"></b-spinner>
      <span v-else>{{ $t("button.save") }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BSpinner, BFormGroup } from "bootstrap-vue";
import FormInput from "./formInput";
import NInput from "@/components/NInput";
import Repository from "@/repositories/RepositoryFactory";

const PaymentAdjustmentRepository = Repository.get("paymentAdjustment");

export default {
  components: {
    BModal,
    BSpinner,
    BFormGroup,

    NInput,
  },
  data() {
    return {
      loading: false,
      isShow: false,
      data: {
        paymentId: null,
        amount: null,
        documentId: null,
        remark: null,
      },
    };
  },
  methods: {
    show(item) {
      this.data = {
        paymentId: item.id,
        amount: null,
        documentId: null,
        remark: null,
      };
      this.isShow = true;
    },
    hide() {
      this.isShow = false;
    },
    handleOk(bvModal) {
      bvModal.preventDefault();
      if (this.loading) {
        return;
      }

      this.$refs.payment.validate().then((success) => {
        if (success) {
          this.$bvModal
            .msgBoxConfirm(this.$t("alert.unableToRevertAfterSave"), {
              title: this.$t("alert.areYouSure"),
              size: "sm",
              buttonSize: "sm",
              okVariant: "success",
              okTitle: this.$t("button.save"),
              cancelTitle: this.$t("button.cancel"),
              cancelVariant: "outline-secondary",
              hideHeaderClose: false,
              centered: true,
            })
            .then((value) => {
              if (value) {
                this.submit();
              }
            });
        }
      });
    },
    submit() {
      this.loading = true;
      PaymentAdjustmentRepository.topUp(this.data)
        .then((response) => {
          this.hide();
          this.$emit("success");
        })
        .catch((error) => {
          if (error.response?.status == 422) {
            this.$refs.payment.setErrors(error.response?.data?.errors);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
  },
  setup() {
    const formInput = [...FormInput];

    return {
      formInput,
    };
  },
};
</script>